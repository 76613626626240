.dark .container-background {
  background-color: rgb(17, 18, 23);
}

.dark .dark-text-white {
  color: #CCCCDC;
}

.dark .dark-text-blue {
  color: #CCCCDC;
}

.dark .dark-text-green {
  color: #198754;
}

.dark .incidentRecentHistory {
  background-color: #181b1f;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(128, 128, 128, 0.1);
  margin-bottom: 2.5rem;
  border: 1px solid rgba(128, 128, 128, 0.5);
}

.dark .top-container {
  padding: 10px 25px;
  border-radius: 8px;
  background-color: #181b1f;
  box-shadow: 0px 20px 40px rgba(9, 9, 9, 0.1);
  margin-bottom: 2.5rem;
  /* margin:0 0.8rem !important; */
  border: 1px solid rgba(128, 128, 128, 0.5);
}

.dark .container-background-gray {
  background-color: #181b1f;
}

.dark .accordion-flush .accordion-item .accordion-button {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  background-color: #181b1f;
  color: #CCCCDC;
}

.dark .accordion-button:not(.collapsed) {
  color: #CCCCDC;
  background-color: #181b1f;
  box-shadow: none !important;
}

.dark .accordion-flush .accordion-item .accordion-button {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.dark .accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.dark .accordion-body {
  background-color: #111217;
}

.dark .accordion-body .body-content {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}

.dark .accordion-body .body-content:last-child {
  border-bottom: none !important;
  padding-bottom: 8px;
}

.dark .accordion-body .body-content:first-child {
  padding-top: 8px;
}

.dark .box-container {
  border-left: 3px solid #00850d;
  border-top: 1px solid rgba(128, 128, 128, 0.5);
  border-right: 3px solid #00850d;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  /* margin: 20px 0; */
  margin: 5px !important;
}

.dark .box-container1 {
  border-left: 3px rgb(17, 18, 23);
  border-top: 1px rgb(17, 18, 23);
  border-right: 3px rgb(17, 18, 23);
  border-bottom: rgb(17, 18, 23);
  /* margin: 20px 0; */
  margin: 10px 5px 2px 5px !important;
}

.dark .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.dark .top-container-popUp {
  padding: 0px 0px;
  border-radius: 8px;
  background-color: #181b1f;
  box-shadow: 0px 20px 40px rgba(9, 9, 9, 0.1);
  margin-bottom: 0rem;
}

.dark .container-background-header {
  background-color: #181b1f !important;
  border: 1px solid #2c2f33;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}



.dark .btn-login {
  border-color: #3D71D9;
  border-width: 0.1rem !important;
  color: #3D71D9 !important;
}

.dark .btn-login:hover {
  background-color: #3D71D9;
  color: white !important;
}

.dark .btn-subscribe {
  background-color: #3D71D9;
}

.dark .btn-support {
  background-color: red !important;
  color: white !important;
}

.dark .support-text {
  color: white;
}

.dark .subscribe-text {
  color: white;
}

.dark .logo-text-header {
  font-family: BuenosAires-Regular;
  color: #CCCCDC;
  font-size: 24px;
  line-height: 32px;
}

.dark .container-background-notification-tab {
  background-color: #181b1f;
}

.dark .nav-pills .nav-link {
  margin-left: 2px;
  padding: 7px 10px;
  font-size: 15px;
  color: #CCCCDC;
  border-bottom: 3px solid transparent;
  border-radius: 0;
}

.dark .nav-pills .nav-link.active {
  background-color: transparent !important;
  font-weight: bold;
  color: #CCCCDC;
}

.dark .offcanvas-body ul {
  list-style: none;
  padding-left: 0px;
  line-height: 2.2rem;
  color: #CCCCDC;
}

.dark .offcanvas-body ul a {
  color: #CCCCDC;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 7px;
}

.dark .offcanvas-body ul a:hover {
  color: #ffc107;
  text-decoration: underline;
}

.dark .close-btn {
  color: white;
  filter: invert(1);
}

.dark .phone-btn {
  color: #3D71D9;
}

.container-background-light-mode {
  background-color: #EFF4F8;
}

.logo-text-header-light {
  font-family: BuenosAires-Regular;
  color: #000;
  font-size: 24px;
  line-height: 32px;
}

.dark .btn-nav-color-list {
  color: white !important;
}

.dark .subscribe-modal-content {
  background-color: black;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  max-width: 90%;
  position: relative;
}

.dark .nav-pills .nav-link.active .border-bstyle {
  background: #0dcaf0 !important;
}

.dark .dark-theme-icon {
  margin-right: 2.5rem;
  background-color: #2B2B2B;
  border: 1px solid #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.dark .bg-bell-noti:hover {
  background-image: url('../image/Shape-bell-white.svg') !important;
  cursor: pointer;
  cursor: pointer !important;
}

.dark .bg-bell-noti:active {
  background-image: url('../image/Shape-bell-white.svg') !important;
}

.dark .accordion-button::after {
  flex-shrink: 0 !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-left: auto !important;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22white%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z%22/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;

  background-repeat: no-repeat !important;
  background-size: 1.25rem !important;
  transition: transform .2s ease-in-out !important;
}


.dark .affected-components-border {
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
}

.dark .dark-text-white-dialog {
  color: #CCCCDC;
}

.dark .sub-email-text {
  color: #CCCCDC;
}

.dark .sub-des-text {
  color: #CCCCDC;
}

.dark .form-select-back {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.dark .modal-box-container-dialog {
  position: fixed;
  outline: 0px;
  padding: 20px;
  border: 1px solid #ccccdc1f;
  border-radius: 10px;
  background-color: #181b1f;
  color: #000;
  min-width: 40%;
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
}

.dark .fixed-top-container-sub {
  position: sticky;
  top: -1rem !important;
  background: hsl(214, 13%, 11%) !important;
  z-index: 1000;
}

.dark .modal-box-container-dialog-sub {
  transition: none !important;
  position: fixed;
  outline: 0px;
  padding: 20px;
  border: 1px solid #ccccdc1f;
  border-radius: 10px;
  background-color: #181b1f;
  color: #000;
  z-index: 1000;
  top: 50%;
  min-width: 40%;
  left: 50%;
  max-width: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  overflow: auto;
}


@media (max-width: 600px) {
  .dark .modal-box-container-dialog-sub {
    position: fixed;
    transition: none !important;
    outline: 0px;
    padding: 20px;
    border: 1px solid #ccccdc1f;
    border-radius: 10px;
    background-color: #181b1f;
    color: #000;
    z-index: 1000;
    height: 50vh;
    width: 90vw !important;
    max-width: 90vw !important;
    min-width: 90vw !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
  }
}
.dark .container-background-gray-border {
  background-color: #181b1f;
  border: 1px solid #2c2f33

}