






/* new */

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}


.nav-pills .nav-link {
  margin-left: 2px;
  padding: 7px 10px;
  font-size: 15px;
  color: #000;
  border-bottom: 3px solid transparent;
  border-radius: 0;
}

.nav-pills .nav-link.active {
  background-color: transparent !important;
  font-weight: bold;
  color: #0f2771;
}
.nav-pills .nav-link.active .border-bstyle {
  background: #0f2771 !important;
}
.container-background-notification-tab {
  background-color: white;
}

.support-text {
  color: red;
}
.btn-login {
  border-color: #0d6efd;
  color: #0d6efd !important;
}

.btn-login:hover{
  background-color:#0d6efd ;
  color: white  !important;
}
.btn-login-default{
  border-color: #0d6efd;
  color: #0d6efd !important;
}

.btn-login-default:hover{
  background-color:#0d6efd ;
  color: white  !important;
}
.btn-subscribe {
  background-color: #0d6efd;
}

.btn-support {
  border-color: #dc3545  !important;
  color: #dc3545 !important;
}
.btn-support:hover{
  background-color: red;
  color: white  !important;
}
.btn-support-default {
  border-color: red;
  color: #dc3545 !important;
}
.btn-support-default:hover{
  background-color: red;
  color: white  !important;
}
.container-background-header {
  background-color: #EFF4F8 !important;
}


.box-container {
  border-left: 3px solid #00850d;
  border-top: 1px solid #eee;
  border-right: 3px solid #00850d;
  border-bottom: 1px solid #eee;
  /* margin: 20px 0; */
  margin: 10px 0 !important;
}

.box-container1 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 10px 0 !important;
}

.offcanvas-body ul a:hover {
  color: #006699;
  text-decoration: underline;
}


.offcanvas-body ul {
  list-style: none;
  padding-left: 0px;
  line-height: 2.2rem;
  color: #333;
}

.offcanvas-body ul a {
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 7px;
}


/* custom css itech */
.offcanvas-body ul {
  list-style: none;
  padding-left: 0px;
  line-height: 2.2rem;
  color: #333;
}

.offcanvas-body ul a {
  color: #333;
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 7px;
}



.offcanvas-start {
  width: 300px;
}

 .nav-pills .nav-link {
  margin-left: 2px;
  padding: 7px 10px;
  font-size: 15px;
  color: black;
  border-bottom: 3px solid transparent;
  border-radius: 0;
}

 .nav-pills .nav-link.active {
  background-color: transparent !important;
  font-weight: bold;
  color: black;
}

.logo-text-header{
  font-family: BuenosAires-Regular;
  color: #000;
  font-size: 24px;
  line-height: 32px;
}
.incidentRecentHistory{
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(9, 9, 9, 0.1);
  margin-bottom: 2.5rem;
}
.btn-nav-color-list{
  color: black !important;
}
.btn-nav-color-list1{
  color: black !important;
}
.subscribe-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  max-width: 90%;
  position: relative;
}
.nav-pills .nav-link.active .border-bstyle {
  background: #0f2771 !important;
}
 .dark-theme-icon {
  margin-right: 2.5rem;
  background-color: #f5f5f5; border: #f5f5f5;
}

.bg-bell-noti:hover{
  background-image: url('../image/Shape-bell-blue.svg') !important;
  cursor:pointer;
  cursor: pointer !important;
}
.bg-bell-noti:active{
  background-image: url('../image/Shape-bell-blue.svg') !important;
}

.bg-bell-noti-light:hover{
  background-image: url('../image/Shape-bell-blue.svg') !important;
  cursor:pointer;
  cursor: pointer !important;
}
.bg-bell-noti-light:active{
  background-image: url('../image/Shape-bell-blue.svg') !important;
}
.accordion-button::after {
  flex-shrink: 0 !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-left: auto !important;
  content: "";
  /* SVG converted to a single line and URL-encoded */
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22%230c63e4%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z%22/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-size: 1.25rem !important;
  transition: transform .2s ease-in-out !important;
}
.container-background-white
{
  background-color: white;
}



.offcanvas-body-default ul a:hover {
  color: #006699;
  text-decoration: underline;
}


.offcanvas-body-default ul {
  list-style: none;
  padding-left: 0px;
  line-height: 2.2rem;
  color: #333;
}

.offcanvas-body-default ul a {
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 7px;
}


/* custom css itech */
.offcanvas-body-default ul {
  margin-top: 4rem !important;
  list-style: none;
  padding-left: 0px;
  line-height: 2.2rem;
  color: black;
}

.offcanvas-body-default ul a {
  color: #333;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #333;
  padding: 7px;
  margin-left: 1rem;
  margin-right: 1rem;
}



.offcanvas-start-default {
  width: 300px;
}

.close-btn-default {
  position: absolute;
  top: 20px;
  margin-bottom: 10rem  !important;
  right: 20px;
  cursor: pointer;
}
.affected-components-border{
  border: 1px solid black; border-radius: 10px; padding: 10px;
}
.dark-text-white-dialog{
  color: black;
}
.sub-email-text{
  color: blue;
}
 .sub-des-text{
  color: grey;
}
.form-select-back {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}
.modal-box-container-dialog {
  position: fixed;
  outline: 0px;
  padding: 20px;
  border: 1px solid #ccccdc1f;
  border-radius: 10px;
  background-color: #ffffff;
  color: #000;
  min-width: 40%;
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
}
.fixed-top-container-sub {
  position: sticky;
  top: -1rem !important;
  background: white !important;
  z-index: 1000;
}
.fixed-top-container-sub-light {
  position: sticky;
  top: 0;
  background: white !important;
  z-index: 1000;
}

.modal-box-container-dialog-sub {
  position: fixed;
  transition: none !important;
  max-height: 80%;
  max-width: 50%;
  outline: 0px;
  min-width: 40%;
  padding: 20px;
  border: 1px solid #ccccdc1f;
  border-radius: 10px;
  background-color: #ffffff;
  color: #000;  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; }
.modal-box-container-dialog-sub-light {
  position: fixed;
  transition: none !important;
  max-width: 50%;
  outline: 0px;
  max-height: 80%;
  min-width: 40%;
  padding: 20px;
  border: 1px solid #ccccdc1f;
  border-radius: 10px;
  background-color: #ffffff;
  color: #000;  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; /* Add this line to enable scrolling */}


.modal-box-container-dialog-sub::-webkit-scrollbar {
    width: 5px !important;
  }

.modal-box-container-dialog-sub::-webkit-scrollbar-track {
    background-color: #e4e4e4 !important;
    border-radius: 100px !important;
  }

.modal-box-container-dialog-sub::-webkit-scrollbar-thumb {
    border-radius: 100px !important;
    background-image: linear-gradient(180deg, white 0%, #708ad4 99%) !important;
    box-shadow: inset 1px 2px 5px 0 rgba(255, 255, 255, 0.5) !important;
  }

  .modal-box-container-dialog::-webkit-scrollbar {
    width: 5px !important;
  }

.modal-box-container-dialog::-webkit-scrollbar-track {
    background-color: #e4e4e4 !important;
    border-radius: 100px !important;
  }

.modal-box-container-dialog::-webkit-scrollbar-thumb {
    border-radius: 100px !important;
    background-image: linear-gradient(180deg, white 0%, #708ad4 99%) !important;
    box-shadow: inset 1px 2px 5px 0 rgba(255, 255, 255, 0.5) !important;
  }
  @media (max-width: 600px) {
    .modal-box-container-dialog-sub {
      position: fixed;
      transition: none !important;
      outline: 0px;
      padding: 20px;
      border: 1px solid #ccccdc1f;
      border-radius: 10px;
      background-color: white;
      color: #000;
      z-index: 1000;
      height: 50vh;
      width: 90vw !important;
      max-width: 90vw !important;
      min-width: 90vw !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: auto;
    }
    .modal-box-container-dialog-sub-light {
      height: 50vh;
      position: fixed;
      transition: none !important;
      outline: 0px;
      padding: 20px;
      border: 1px solid #ccccdc1f;
      border-radius: 10px;
      background-color: white;
      color: #000;
      z-index: 1000;
      width: 90vw !important;
      max-width: 90vw !important;
      min-width: 90vw !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: auto;
    }
  }
