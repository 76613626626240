/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import url('./assets/css/default-v2-dark.css');
@import url('./assets/css/default-v2-light.css');
/* Styles to ensure full height */



.mt-90 {
  margin-top: 90px;
}


@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.loadingTable, .loadingData {
  width: 100%;
}

  .loadingTable tr {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

    .loadingTable tr td {
      height: 50px;
      vertical-align: middle;
      padding: 8px;
    }

    .loadingTable tr td, .loadingTable tr th {
      min-width: 100px !important;
      min-height: 10px !important;
    }

  .loadingTable thead tr th span, .loadingData span {
    margin: 1% auto;
  }

  loadingTable tr .dcol span, .loadingTable tr th span, .loadingData span {
    max-width: 50px !important;
    margin: auto;
  }

  .loadingTable tr td span, .loadingTable tr th span {
    display: block;
    height: 20px;
    max-width: 200px;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .loadingData span {
    display: block;
    min-height: 20px;
    max-width: 1000px !important;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .loadingData h1 span {
    min-height: 35px !important;
  }


#statusPage .dataTables_empty {
  display: none !important;
}

.color-blk {
  color: black;
}

.min-h-400 {
  min-height: 400px;
}

@media print {
  .main, .printButton {
    visibility: hidden;
  }


  .section-to-print, .section-to-print * {
    visibility: visible;
  }

  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}


.text-right-f {
  text-align: right !important;
}



.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 0 10px 0 !important;
}

.mat-form-field-wrapper {
  padding-bottom:0;
}

.content-overflow-hide {
  max-height: 68px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.grecaptcha-badge {
  visibility: hidden;
}


.flex-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subscriberComponents .mat-checkbox-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.subscriberComponents .mat-checkbox {
  font-family: fontsnew;
}

.subscriberComponents .mat-checkbox-input {
  border-radius: 0.25em;
}

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .subscriberComponents .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: #0d6efd;
  border-color: #0d6efd;
}


.full-dialog .mat-dialog-container {
  padding: 0;
  z-index: 1030;
}

.cdk-overlay-container {
  z-index:10001;
}


.full-dialog-container .mat-dialog-container {
  padding: 0px !important;
}

@media print {
  .pagebreak {
    page-break-after: always;
  }
  /* page-break-after works, as well */
}


.logo-size {
  width:50px;
  height:50px;
}


.custom-multiselect .multiselect-dropdown .dropdown-btn {
  border: 1px solid #ced4da;
  margin-top: 4px;
  padding: 5px 12px;
}

  .custom-multiselect .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    top: 5px;
  }

  @media  (max-width: 600px) {
    .dataTables_length{
      margin-bottom: 3px  !important;
      text-align: left !important;
    }    
    .dataTables_filter {
      text-align: right !important;
    }
    
    
  }

  